import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { PiMapPinLineLight } from "react-icons/pi";
import { TbClockHour5 } from "react-icons/tb";

const Contact = () => {
    return (
        <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d116755.71611781648!2d90.28913942238178!3d23.86776127453852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3755c300198893d7%3A0x2a4a3439bf35e983!2sE%2C%20Plot%201%263%20Rd%204%2C%20Dhaka%201230!3m2!1d23.867783199999998!2d90.371541!5e0!3m2!1sen!2sbd!4v1713500108496!5m2!1sen!2sbd" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div className="mx-auto w-11/12 lg:w-10/12 py-12">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                  <div>
                      <h2 className="text-3xl font-bold">Contact Us</h2>
                      <p className="my-6">If you would like to know more about our policies, you can consult our Terms and Conditions. You will also be able to follow your order (tracking number will be provided in an e-mail after ordering). You wish to return some items?</p>
                      <div className="flex">
                      <PiMapPinLineLight className="text-[55px] mr-4"/>
                          <div>
                              <h3 className="text-lg font-medium mb-2.5">Address</h3>
                              <p className="text-[#828282] text-[15px]">House/Plot # 01, Road # 04, Block # E (Nearest DPS School), Sector # 15, Uttara, Dhaka.</p>
                          </div>
                      </div>
                      <div className="flex items-center my-6">
                      <FaPhoneAlt className="text-[30px] mr-4"/>
                          <div>
                              <h3 className="text-lg font-medium mb-2.5">Phone</h3>
                              <p className="text-[#828282] text-[15px]">01911558258</p>
                          </div>
                      </div>
                      <div className="flex items-center">
                      <TbClockHour5 className="text-[40px] mr-4"/>
                          <div>
                              <h3 className="text-lg font-medium mb-2.5">Open Hours</h3>
                              <p className="text-[#828282] text-[15px]">24 Hours Online</p>
                              <p className="text-[#828282] text-[15px]">Offiline: Saturday to Sunday 07:30 - 10:30</p>
                          </div>
                      </div>
                  </div>
                  {/* ------------------ */}
                  <div>
                      <h2 className="text-3xl font-bold ">Send Us An Message</h2>
                      <div className=" w-full mt-6">
                       <input type="text" id="name" placeholder="Your Name"
                       className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" />
                      </div>
                      <div className=" w-full my-4">
                       <input type="text" id="email" placeholder="Your Email"
                       className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" />
                      </div>
                      <div className=" w-full">
                       <textarea  id="message" rows={8}  placeholder="Your Message"
                       className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1" />
                      </div>
                      <button className="relative rounded-full w-full py-3  mt-5 overflow-hidden group bg-[#06BF6D] hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300">
                    <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                    <span className="relative ">SEND</span>
          </button>
                  </div>
              </div>
          </div>
      </div>
    );
};

export default Contact;