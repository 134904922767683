
import React from "react";
import { BsFillEnvelopeOpenFill, BsPinMapFill, BsTelephoneFill, BsYoutube, BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn, FaTwitter, FaFacebookF } from 'react-icons/fa';
import './Footer.css'
import logo from '../../Assets/footer-logo.png'
import ScrollToTop from 'react-scroll-to-top';
import { Link } from "react-router-dom";
import bkash from '../../Assets/bKash-Merchant-Account.png'
import nagad from '../../Assets/nagad-2.webp'


export default function Footer (){
    return (
       <>
       <ScrollToTop smooth top='80' color="#67c177" width="28px" height='28px' />

        <div className='footer-section'>
            <div className='container mx-auto w-11/12 lg:w-10/12'>
                {/* *********Footer-Top********** */}
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 footer-top'>
                    {/* ********about********** */}
                    <div>
                        <h3>about foods plats</h3>
                        <p>At Foods Plants, we are dedicated to providing not only fresh but also sustainably sourced vegetables. Our commitment to quality extends beyond the produce itself; it encompasses the very ethos of our business.</p>
                        {/* ********social********* */}
                        <div className='social '>
                            <h3 className='mt-5'>Online Payments by</h3>
                            <div className="flex items-center">
                                <img className=" w-24 mr-3" src={bkash} alt="" srcset="" loading="lazy"/>
                                <img className=" w-24 " src={nagad} alt="" srcset="" loading="lazy"/>
                            </div>
                        </div>
                    </div>

                    {/* ********Location*********** */}
                    <div>
                        <h3>Location Store</h3>
                        <div className='flex items-center'>
                            <div className='contact-icon'>
                                <BsPinMapFill />
                            </div>
                            <p>House/Plot # 01, Road # 04, Block # E (Nearest DPS School), Sector # 15, Uttara, Dhaka.</p>
                        </div>
                        <div className='flex items-center my-6'>
                            <div className='contact-icon'>
                                <BsTelephoneFill />
                            </div>
                            <p>01911558258</p>
                        </div>
                        <div className='flex items-center'>
                            <div className='contact-icon'>
                                <BsFillEnvelopeOpenFill />
                            </div>
                            <p>bdfoodsplants@gmail.com</p>
                        </div>
                    </div>
                    {/* *********Newslatter********** */}
                    <div>
                        <h3>Subscribe To Our Newsletter</h3>
                        <p>Stay tune for updates</p>
                        <div className="form-control w-full mt-4">
                            <div className="relative">
                                <input type="text" placeholder="username@site.com" className="input input-bordered w-full pr-16 py-2" />
                                <button className="w-28 text-white bottom-0 absolute top-0 footer-subs right-0 ">Subscribe</button>
                            </div>
                        </div>
                        {/* ********social********* */}
                        <div className='social'>
                            <h3 className='mt-3'>Contact with us</h3>
                            <div className='sociel flex content-start'>
                                <a href='/' className=' '><FaFacebookF className='footer-icon fa' /></a>
                                <a href='/' className=' '><FaTwitter className='footer-icon tw' /></a>
                                <a href='/' className=' '><BsYoutube className='footer-icon yt' /></a>
                                <a href='/' className=' '><FaLinkedinIn className='footer-icon li' /></a>
                                <a href='/' className=' '><BsInstagram className='footer-icon in' /></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* **********Footer-Bottom********* */}
                <div>
                    <div className="flex items-center justify-between">
                        <div className="footer-divider "></div>
                        <div className=""><img src={logo} alt="" loading="lazy"/></div>
                        <div className="footer-divider "></div>
                    </div>
                    <p className='text-center text-white mt-4'>FoodsPlats © 2023 eCommerce vegetable Store. All Rights Reserved.Design by <Link to='https://rifatjahan.xyz/'target="_blank" rel="noopener noreferrer" style={{color:"#67c177"}}>Rifat Jahan</Link></p>
                </div>
            </div>
        </div>
       </>
    );
};
