import { baseApi } from "../../api/baseApi";


const productApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // getProducts: builder.query({
        //     query: ({ page, limit }) => `/products?page=${page}&limit=${limit}`,
        //     providesTags:["product appear"]
        // }),
        getProducts: builder.query({
            query: ({ page, limit, searchTerm = '' }) => `/products?page=${page}&limit=${limit}&searchTerm=${encodeURIComponent(searchTerm)}`,
            providesTags: ["product"], // Corrected tag name
        }),
        singleProducts: builder.query((id) => ({
            query: () => `/products/${id}`
        })),

        createProduct: builder.mutation((newProduct) => ({
            query: () => ({
                url: `/products`,
                method: 'POST',
                body: newProduct
            }),
        })),
    }),
});

export const { useGetProductsQuery, useSingleProductsQuery, useCreateProductMutation } = productApi;
