import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from "react-icons/io";
import { GiShoppingCart } from "react-icons/gi";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { addToCart, clearCart, decreaseCart, getTotals, removeFromCart } from "../redux/features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImBin2 } from "react-icons/im";
import Farmer from '../Assets/Farmer with vegetables.gif'


const Cart = () => {
  const cart = useSelector((state) => state.cart);
  console.log(cart?.cartItems);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotals(cart));
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };

  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };

  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };

  const handleClearCart = () => {
    dispatch(clearCart(cart));
  };
  const totalPrice = cart.cartTotalAmount;

  // Calculate the percentage of the total price
  const percentage = (totalPrice / 399) * 100; // Assuming 399 is the threshold for full red background

  // Set the width percentage
  const widthPercentage = `${percentage}%`;

  // Determine the background color based on the percentage
  let bgColor = 'bg-red-500'; // Default red background color
  let textToShow = "Shop 399 or more get free delivery";

  if (percentage >= 100) {
    bgColor = 'bg-green-500'; // Change to green when 100% or more
    textToShow = "Congratulations! You've qualified for free delivery!";
  }


  return (
    <div >
      <div className="drawer drawer-end">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle " />
        <div className="drawer-content">
          <label htmlFor="my-drawer-4" className="drawer-button ">
          <div className="indicator">
          <GiShoppingCart className="text-[#06BF6D] text-4xl font-extrabold" />
          <span className="badge badge-sm indicator-item">{cart?.cartItems?.length}</span>
        </div>
          </label>
        </div>
        <div className="drawer-side z-50 h-[100vh]">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <div className="menu  w-80 min-h-full bg-base-200 text-base-content relative">
            {/* ----------- */} 
          <div className="relative mb-5 bg-[#7A7879] -z-30">
          <span className={`absolute top-0 left-0 bottom-0 -z-20 transition-all duration-500 ${bgColor}`} style={{ width: widthPercentage }}></span>
          <h1 className="font-semibold text-[12px] px-2 my-2 flex z-50 text-white ">
            {textToShow}
          </h1>
        </div>

        <div>
            <div className=" mb-[18%]">
            {cart?.cartItems &&
              cart?.cartItems?.map((cartItem) => (
                <div key={cartItem._id}>
                  <div className="flex justify-between items-center px-5">
                    <div>
                      <button onClick={() => handleAddToCart(cartItem)}><IoIosArrowUp className="text-lg"/></button>
                      <p className="text-xl ml-1">{cartItem.cartQuantity}</p>
                      <button onClick={() => handleDecreaseCart(cartItem)}><IoIosArrowDown className="text-lg"/> </button>
                    </div>
                    <img src={cartItem.img} alt={cartItem.name} className="w-[70px] h-[70px]" loading="lazy"/>             
                    <h3>{cartItem.name}</h3>
                    <p>{cartItem.price * cartItem.cartQuantity}</p>
                    <button onClick={() => handleRemoveFromCart(cartItem)}> <IoIosClose className="text-4xl" /></button>
                  </div>
                </div>
              ))
              }
            {cart?.cartItems<=1 ?
            <>
            <img src={Farmer } alt="Vegetables" className=" rounded-full mt-6 w-[95%]" loading="lazy"/>
            <p className="my-5 text-xl text-center text-zinc-500 mr-5">Your shopping bag is empty. Start shopping</p>
            </>
            :
            <>
            <button className="text-lg text-red-600 font-black mb-6 mt-12 ml-12 flex items-center justify-between w-[70%] " onClick={() => handleClearCart()}>
              Clear Cart <ImBin2  className="text-2xl"/></button>
              <div className="flex justify-center ">
            <Link to='/checkout' ><button className="bg-[#06bf6c8e] py-2 px-6 text-[18px] font-medium">Place Order</button></Link>
            <Link to='/cart' ><button className="bg-[#06BF6D] py-2 px-6 text-[18px] font-medium">Cart</button></Link>
             </div>
            </>
             }
          </div>
        </div>
     
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
