import React from 'react'


const FruitHealth = () => {
    return (
        <div className="py-10">
            <div className="mx-auto w-11/12 lg:w-10/12 ">
                    <h1 className="text-[#06BF6D] font-semibold text-center text-3xl mb-8">Vegetables And Health</h1>
                    <div className="grid md:grid-cols-3 grid-cols-1 justify-center items-center">
                        <div className='md:block grid sm:grid-cols-2 grid-cols-1 justify-center items-center'>
                            {/* ------ */}
                            <div className='flex justify-end '>
                                <div className='text-right mr-3'>
                                    <h3>Tomato</h3>
                                    <p>Tomatoes are rich in antioxidants like lycopene, which may reduce the risk of certain cancers and promote heart health</p>
                                </div>
                                <div>
                                <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={80} height={80} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/vw6k1g7ursxkz2norurg' alt="" loading="lazy" /></div>
                                </div>
                            </div>
                            {/* ------ */}
                            <div className='flex my-8 justify-end '>
                                <div className='text-right mr-3'>
                                    <h3>Cucumber</h3>
                                    <p>Cucumbers are not only refreshing but also packed with nutrients, including vitamins K and C, as well as potassium</p>
                                </div>
                                <div>
                                <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={80} height={80} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/vimwwknvlrn4qs7kgrps' alt="" loading="lazy" /></div>
                                </div>
                            </div>
                            {/* ------ */}
                            <div className='flex justify-end '>
                                <div className='text-right mr-3'>
                                    <h3>Carrot</h3>
                                    <p>Carrots are a powerhouse of beta-carotene, which the body converts into vitamin A, essential for vision</p>
                                </div>
                                <div>
                                <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={80} height={80} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/tknvegqrvmlojwaaqox4' alt=""  loading="lazy"/></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/wqf9hpvf7siofmh3yzpn' alt="" className='mx-auto sm:mt-8 md:mt-0 my-8'loading="lazy"/>
                        </div>
                        {/* -------right side-------- */}
                        <div className='md:block grid sm:grid-cols-2 grid-cols-1 justify-center items-center'>
                            {/* ------ */}
                            <div className='flex justify-start '>
                            <div>
                            <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={80} height={80} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/g05crmcwqtxxebw65dng' alt="" loading="lazy" /></div>
                            </div>
                                <div className=' ml-3'>
                                    <h3>Capsicum</h3>
                                    <p>Capsicums, also known as bell peppers, are loaded with vitamin C, which boosts immunity and collagen production for healthy skin</p>
                                </div>
                            </div>
                            {/* ------ */}
                            <div className='flex my-8 justify-start '>
                            <div>
                            <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={80} height={80} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/huu29si6c94gu8wqeauo' alt=""  loading="lazy"/></div>
                            </div>
                                <div className=' ml-3'>
                                    <h3>Broccoli</h3>
                                    <p>Broccoli is a nutritional powerhouse, packed with vitamins, minerals, and antioxidants</p>
                                </div>
                            </div>
                            {/* ------ */}
                            <div className='flex justify-start '>
                           <div>
                           <div className='border-dotted border-2 border-[#06BF6D] w-20 h-20 rounded-full p-3 flex items-center justify-center'><img width={100} height={100} src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/eelzj3yj1nbruqyn94i1' alt="" loading="lazy" /></div>
                           </div>
                                <div className=' ml-3'>
                                    <h3>Beetroot</h3>
                                    <p>Beetroots are rich in nitrates, which can help lower blood pressure and improve athletic performance</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default FruitHealth;