
import React, { useRef } from 'react';
import './Hero.css'
// import Swiper core and required modules
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
//************React-Ico************ */
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";






const Hero = () => {
    const swiperRef = useRef();
    
    return (
        <>
            <div className='grid lg:grid-cols-2 grid-cols-1 items-center mx-auto w-11/12 lg:w-10/12 py-12 '>
                <div className='slider  '>
                    <Swiper
                        modules={[Autoplay, Navigation,]}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2000,
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper
                        }}
                        
                        loop={true}
                    >
                        <SwiperSlide>
                            <div className='slider_bg1 lg:h-[calc(100vh-30vh)]'>
                                <div className='slider_content text-center'>
                                    <h6>Fruit Organic Food</h6>
                                    <h1>Big Sale</h1>
                                    <a href="#" className='inline-block'>Shop Now < BsArrowRight className='inline-block mb-1' /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider_bg2 lg:h-[calc(100vh-30vh)] '>
                                <div className='slider_content text-center'>
                                    <h6>Fruit Organic Food</h6>
                                    <h1>Big Sale</h1>
                                    <a href="#" className='inline-block'>Shop Now < BsArrowRight className='inline-block mb-1' /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider_bg3 lg:h-[calc(100vh-30vh)] '>
                                <div className='slider_content text-center'>
                                    <h6>Fruit Organic Food</h6>
                                    <h1>Big Sale</h1>
                                    <a href="#" className='inline-block'>Shop Now < BsArrowRight className='inline-block mb-1' /></a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <button className='swiper-custom-button-prev' onClick={() => (swiperRef.current)?.slidePrev()}><BsArrowLeft /></button>
                        <button className='swiper-custom-button-next' onClick={() =>(swiperRef.current )?.slideNext()}><BsArrowRight /></button>
                    </Swiper>
                </div>
                {/* **********Main-Product*********** */}
                <div className='grid grid-cols-2 gap-1 ' >
                    {/* *********grid-top********       */}
                    <div className='lg:grid-rows-2 '>
                        <div className='mb-1 hero_main_content text-white'>
                            <img className='w-full lg:h-[calc(100vh-65.3vh)]' src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/z4vfyvfrd2zxdjto9tid' alt="" loading="lazy"/>
                            <div className='overlay-img text-center'>
                                <h1>orange Fruit</h1>
                                <p className='mb-2'>&#2547; 550</p>
                                <a href="#" className='inline-block'>Shop Now < BsArrowRight className='inline-block ' /></a>
                            </div>
                        </div>
                        <div className=' hero_main_content text-white'>
                            <img className='w-full lg:h-[calc(100vh-65.3vh)]' src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/nwxlfvqx7xkebabjlb4p' alt="" loading="lazy"/>
                            <div className='overlay-img text-center'>
                                <h1>orange Fruit</h1>
                                <p className='mb-2'>&#2547; 550</p>
                                <a href="#" className='inline-block'>Shop Now < BsArrowRight className='inline-block mb-1' /></a>
                            </div>
                        </div>
                    </div>
                    {/* *********grid-bottom********       */}
                    <div className='lg:grid-rows-2'>
                        <div className='mb-1  hero_main_content text-white'>
                            <img className='w-full lg:h-[calc(100vh-65.3vh)] ' src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/toivtczkdjuknuqa8b0p' alt="" loading="lazy"/>
                            <div className='overlay-img text-center'>
                                <h1>orange Fruit</h1>
                                <p className='mb-2'>&#2547; 550</p>
                                <a href="#" className='inline-block '>Shop Now < BsArrowRight className='inline-block ' /></a>
                            </div>
                        </div>
                        <div>
                            <div className=' hero_main_content text-white'>
                                <img className='w-full lg:h-[calc(100vh-65.3vh)]' src='https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/qhqo0xixnsijafyxlz0b' alt="" loading="lazy"/>
                                <div className='overlay-img text-center'>
                                    <h1>orange Fruit</h1>
                                    <p className='mb-2'>&#2547; 550</p>
                                    <a href="/" className='inline-block'>Shop Now < BsArrowRight className='inline-block mb-1' /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;