import React from 'react';
import { useDispatch } from 'react-redux';
import { GiBeachBag } from 'react-icons/gi'; 
import { addToCart } from '../redux/features/cart/cartSlice';
import { toast } from 'react-toastify';

export default function ProductCard({ product }) {
  const dispatch = useDispatch();

  const handleAddProduct = (product) => {
    dispatch(addToCart(product));
    toast.success(`${product.name} added to cart!`, {
      position: "top-right",
      autoClose: 1000, 
   
    });
  };

  return (
    <div>
      <div className="h-[100%] p-5  text-center">
        <img src={product?.img} alt="product" loading="lazy" className=' mx-auto'/>
        <h1 className="text-xl font-medium h-14 mt-4 text-[#1a1a1a]">{product?.name}</h1>
        <p className='text-[#06BF6D] text-lg font-medium'>{product?.amount} <span className='text-[#1a1a1a] font-medium'>{product?.weight}</span></p>
        {product?.discount ?
          <>
            <p className="text-2xl font-bold text-[#e43215] my-1">&#2547; {product?.discount} <span className='line-through text-lg pt-2 text-[#323131]'>&#2547; {product?.price}</span></p>
          </>
          : <p className="text-2xl font-bold text-[#323131] my-1">&#2547; {product?.price}</p>
        }
        <button onClick={() => handleAddProduct(product)} className="relative rounded-full w-full py-1.5 overflow-hidden group bg-[#06BF6D] hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300">
          <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
          <span className="relative flex items-center justify-center"><GiBeachBag className='mr-2' /> Add to cart</span>
          </button>
      </div>
    </div>
  );
}

