import { baseApi } from "../../api/baseApi";


const checkoutApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (newProduct) => ({
                url: `/order`,
                method: 'POST',
                body: newProduct
            }),
        })
    }),
});

export const { useCreateOrderMutation } = checkoutApi;

