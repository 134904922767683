import React from 'react';
import { useState, useEffect } from "react";
import { GiBeachBag } from "react-icons/gi";
import { addToCart } from '../../redux/features/cart/cartSlice';
import { useDispatch } from 'react-redux';
import Loading from '../Loading';
import { toast } from 'react-toastify';


const OfferProduct = () => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);

  useEffect(() => {
    fetch("https://foods-plants-server.vercel.app/api/v1/products")
      .then((res) => res.json())
      .then((data) => {
        setProduct(data.data);
        setLoading(false);
        // Filter data by category
        setSpecialProducts(data.data.filter((product) => product?.menu === 'special'));
        setPopularProducts(data.data.filter((product) => product?.menu === 'popular'));
        setRecentProducts(data.data.filter((product) => product?.menu === 'recent'));
      });
  }, []);

  const dispatch = useDispatch();

  const handleAddProduct = (product) => {
    dispatch(addToCart(product));
    toast.success(`${product.name} added to cart!`, {
      position: "top-right",
      autoClose: 1000, 
   
    });
  };
  if (loading) {
    return <div><Loading/></div>;
  }
  return (
    <div className='mx-auto w-11/12 lg:w-10/12 border-b-2 border-[#06BF6D] pb-10'>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
      <div>
          <h2 className="text-[#06BF6D] font-semibold text-center text-2xl mb-5">Recent</h2>
          <div className='md:block grid grid-cols-3 '>
          {recentProducts.map(product => (
            <div key={product._id}>
              <div className='md:grid md:grid-cols-3 border md:p-3 py-3 items-center justify-center'>
                <img width={100} height={50} src={product.img} alt=''className='mx-auto' loading="lazy"/>
                <div className='col-span-2 text-center'>
                  <p >{product.name}</p>
                  <p>Price: &#2547;{product.price}</p>
                  <button className='text-[#06BF6D] sm:text-[16px] text-[14px] font-semibold  flex items-center justify-center mt-2 w-full' onClick={() => handleAddProduct(product)}>
                    <GiBeachBag className='mr-2' /> Add to cart
                  </button>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
        <div >
          <h2 className="text-[#06BF6D] font-semibold text-center text-2xl mb-5">Special</h2>
          <div className='md:block grid grid-cols-3'>
          {specialProducts.map(product => (
            <div key={product._id}>
              <div className='md:grid md:grid-cols-3 border md:p-3 py-3 items-center justify-center '>
                <img width={100} height={50} src={product.img} alt='' className='mx-auto'loading="lazy"/>
                <div className='col-span-2 text-center'>
                  <p>{product.name}</p>
                  <p>Price: &#2547;{product.price}</p>
                  <button className='text-[#06BF6D] sm:text-[16px] text-[14px] font-semibold  flex items-center justify-center mt-2 w-full' onClick={() => handleAddProduct(product)}>
                    <GiBeachBag className='mr-2' /> Add to cart
                  </button>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
        <div>
          <h2 className="text-[#06BF6D] font-semibold text-center text-2xl mb-5">Popular</h2>
       <div className='md:block grid grid-cols-3'>
       {popularProducts.map(product => (
            <div key={product._id}>
              <div className='md:grid md:grid-cols-3  border md:p-3 py-3 items-center justify-center'>
                <img  src={product.img} alt='' className='mx-auto'loading="lazy"/>
                <div className='col-span-2 text-center'>
                  <p className=' h-12'>{product.name}</p>
                  <p>Price: &#2547;{product.price}</p>
                  <button className='text-[#06BF6D] sm:text-[16px] text-[14px]  font-semibold  flex items-center justify-center mt-2 w-full' onClick={() => handleAddProduct(product)}>
                    <GiBeachBag className='mr-2' /> Add to cart
                  </button>
                </div>
              </div>
            </div>
          ))}
       </div>
        </div>

      </div>
    </div>
  );
};

export default OfferProduct;
