import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md'; // Import close icon
import { Link } from 'react-router-dom';

const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false); // State to manage menu visibility

    const toggleMenu = () => {
        setIsOpen(!isOpen); // Toggle menu visibility
    };

    return (
        <div className="drawer">
            <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={isOpen} />
            <div className="drawer-content">
                {/* Page content here */}
                <label htmlFor="my-drawer" className="drawer-button"><GiHamburgerMenu onClick={toggleMenu} /></label>
            </div> 
            <div className={`drawer-side ${isOpen ? 'open' : ''}`}>
                <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                  {/* Close icon at the top right corner */}
                <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
                {isOpen && (
                <div className="flex justify-end cursor-pointer mb-3">
                    <MdClose size={24} onClick={toggleMenu} />
                </div>
            )}
                     <Link
            to="/"
            className=" font-medium hover:text-[#06BF6D] "
          >
            HOME
          </Link>
          <Link
            to="/fresh-vegetable"
            className=" font-medium my-3 hover:text-[#06BF6D] "
          >
          SHOP
          </Link>
          <Link
            to="/blog"
            className=" font-medium my-3 hover:text-[#06BF6D] "
          >
          BLOG
          </Link>
          <Link
            to="/about"
            className="font-medium mb-3 hover:text-[#06BF6D] "
          >
            ABOUT
          </Link>
          <Link
            to="/contact"
            className="font-medium hover:text-[#06BF6D] "
          >
            CONTACT
          </Link>
                </ul>
            </div>
          
        </div>
    );
};

export default MobileMenu;
