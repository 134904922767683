import  { createBrowserRouter} from'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import Product from '../pages/Product'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import Blog from '../pages/Blog'



  export const router = createBrowserRouter([
    {
      path: '/',
      element: <App/>,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: '/fresh-vegetable',
          element: <Product />,
        },
        {
          path: '/about',
          element: <About />,
        },
        {
          path: '/contact',
          element: <Contact />,
        },
        {
          path: '/cart',
          element: <Cart />,
        },
        {
          path: '/checkout',
          element: <Checkout />,
        },
        {
          path: '/blog',
          element: <Blog/>,
        },
        
      ],
    },
  
    {
      path: '*',
      element: <NotFound />,
    },
  ])