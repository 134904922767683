import React from 'react'
import logo from "../../Assets/logo.png";
import Cart from "../Cart";
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';

const Navbar = () => {
  return (
    <nav className="bg-white border sticky top-0 z-50">
      <div className="flex items-center justify-between mx-auto w-11/12 lg:w-10/12">
        <Link to='/'>
          <img src={logo} alt="" className="w-52 " loading="lazy"/>
        </Link>
        <div className='md:block hidden'>
        <ul className="py-8 ">
          <Link
            to="/"
            className="py-8 px-3 font-medium hover:text-[#06BF6D] hover:border-b-4 hover:border-[#06BF6D]"
          >
            HOME
          </Link>
          <Link
            to="/fresh-vegetable"
            className="mx-2 font-medium py-8 px-3 hover:text-[#06BF6D] hover:border-b-4 hover:border-[#06BF6D]"
          >
          SHOP
          </Link>
          <Link
            to="/blog"
            className="font-medium py-8 px-3 hover:text-[#06BF6D] hover:border-b-4 hover:border-[#06BF6D]"
          >
            BLOG
          </Link>
          <Link
            to="/about"
            className="font-medium py-8 px-3 hover:text-[#06BF6D] hover:border-b-4 hover:border-[#06BF6D]"
          >
            ABOUT
          </Link>
          <Link
            to="/contact"
            className="font-medium py-8 px-3 hover:text-[#06BF6D] hover:border-b-4 hover:border-[#06BF6D]"
          >
            CONTACT
          </Link>
        </ul>
        </div>
        <div className='md:hidden '>
        <MobileMenu/>
        </div>
        <div>
          <Cart/>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
