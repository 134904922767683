import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCreateOrderMutation } from '../redux/features/checkout/checkoutApi';

const Checkout = () => {
  const [createOrder] = useCreateOrderMutation();
  const cart = useSelector ((state) => state.cart);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
      try {
          // Prepare delivery information
          const deliveryInfo = {
              name: data.name,
              phone: data.phone,
              address: data.address,
              note: data.note
          };

          // Prepare order summary
          const orderSummary = {
              products: cart?.cartItems?.map((product) => ({
                  img: product.img,
                  name: product.name,
                  price: product.price,
                  quantity: product.cartQuantity,
                  weight: product.weight,
                  amount: product.amount,
                  subtotal: (product.cartQuantity * product.price).toFixed(2)
              })),
              subtotal: cart.cartTotalAmount.toFixed(2),
              deliveryFee: cart.cartTotalAmount >= 399 ? 0.0 : 30.0,
              total: cart.cartTotalAmount >= 399
                  ? (cart.cartTotalAmount + 0.0).toFixed(2)
                  : (cart.cartTotalAmount + 30.0).toFixed(2),
          };

          // Prepare checkout data
          const checkoutData = {
              deliveryInfo,
              orderSummary,
          };

          // Send checkout data to API
          const response = await createOrder(checkoutData);
          console.log(response);
          toast.success('Order placed successfully');
          reset(); // Reset form after successful submission
      } catch (error) {
          console.error("Error during checkout:", error);
          toast.error('Error occurred during checkout');
      }
  };
    return (
        <div className="mx-auto w-11/12 lg:w-10/12 py-12">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <h1 className="mb-4 font-bold text-2xl uppercase">Billing details</h1>
            <div className=" border border-gray-300 rounded-md p-10 ">
                 <div className="md:flex gap-5">
                 <div className="md:w-1/2 w-full md:mb-0 mb-4">
                    <label htmlFor="name" className="block">Your Name</label>
                    <input
                      type="text"
                      id="name"
                      className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"
                      {...register("name")} required
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <label htmlFor="phone" className="block">Phone Number</label>
                    <input
                      type="text"
                      id="phone"
                      className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"
                      {...register("phone")} required
                    />
                  </div>
                 </div>
                  <div className="md:flex items-center gap-5">
                  <div className="md:w-1/2 w-full mt-4">
                    <label htmlFor="address" className="block">Address</label>
                    <textarea
                      id="address"
                      className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"
                      {...register("address")} required
                    />
                </div>
                  <div className="md:w-1/2 w-full mt-4">
                    <label htmlFor="address" className="blcok">Note (Optional)</label>
                    <textarea
                      id="note"
                      className="w-full b rounded-[5px] p-[10px] bg-[#fafafa] border hover:bg-white focus:ring-1 focus:outline-none focus:ring-gray-400 focus:bg-white mt-1"
                      {...register("note")}
                    />
                </div>
                  </div>
            </div>
          </div>
          <div className=" w-full text-black uppercase my-8">
            <h1 className="mb-4 font-bold text-2xl">Your Order</h1>
            <div className="">
              <div className="flex-grow mb-2  overflow-auto">
                <table className="w-full border-collapse border border-slate-200 p-4">
                  <thead className="text-left ">
                    <tr>
                      <th className="border border-slate-200 p-1.5 font-normal">
                        Product{" "}
                      </th>
                      <th className="border border-slate-200 p-1.5 font-normal">
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart?.cartItems?.map((product, index) => (
                      <tr key={index}>
                        <td className="border border-slate-200 p-1.5">
                          <p>
                            {product.name} ({product.amount}
                            {product.weight}) x {product.cartQuantity}
                          </p>
                        </td>
                        <td className="border border-slate-200 p-1.5 text-[#67c177]">
                          &#x09F3;{" "}
                          {(product.cartQuantity* product.price).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="font-bold">
                      <td className="border border-slate-200 p-1.5">Subtotal </td>
                      <td className="border border-slate-200 p-1.5 text-[#67c177]">
                        &#x09F3; {cart.cartTotalAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td className="border border-slate-200 p-1.5"> Delivery</td>
                      <td className="border border-slate-200 p-1.5 text-[#67c177]">
                        &#x09F3; {cart.cartTotalAmount >= 399 ? 0.0 : 30.0}
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td className="border border-slate-200 p-1.5">Total</td>
                      <td className="border border-slate-200 p-1.5 text-[#67c177]">
                        &#x09F3;{" "}
                        {cart.cartTotalAmount >= 399
                          ? (cart.cartTotalAmount+ 0.0).toFixed(2)
                          : (cart.cartTotalAmount + 30.0).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* ----------cashon-delivery---- */}
          <div className="bg-[#e9e6ed] rounded-[5px] ">
            <div className="p-[15px]">
              <h1 className="text-black">Cash on delivery</h1>
              <p className="bg-[#dcd7e3] rounded-[2px] p-3 text-[#515151] ml-3 mt-2">
                Pay with cash upon delivery.
              </p>
            </div>
            <div className="border border-gray-300 my-3.5"></div>
            <div className=" p-[15px]">
                  <div className="flex items-center">
                  <input type="checkbox"defaultChecked className="checkbox checkbox-xs checkbox-primary mr-2" required/>
                  <span className="label-text">I have read and agree to the website terms and conditions *</span>
                  </div>
           
              <div className="flex justify-end">       <button  className="relative rounded-full py-3 px-6 text-[18px] font-semibold mt-5 overflow-hidden group bg-[#06BF6D] hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300">
          <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
          <span className="relative flex items-center justify-center">Place Order</span>
          </button></div>
            </div>
          </div>{" "}
          {/* ----------cashon-delivery---- */}
        </form>
      </div>
    );
};

export default Checkout;