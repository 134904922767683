import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Facebook = () => {
    return (
        <div>

<MessengerCustomerChat
                pageId="120178301174130"
                appId="3783558465208725"
            /> 
         
        </div>
    );
};

export default Facebook;



