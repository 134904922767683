import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import foodsplantsabout from "../../src/Assets/about/foods-plants.png";
import { Link } from "react-router-dom";

import { FaExchangeAlt, FaShippingFast, FaUserShield } from "react-icons/fa";

const About = () => {
  return (
    <div>
      <div className=" mx-auto w-11/12 lg:w-10/12 py-20">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <div>
            <img
              src={foodsplantsabout}
              alt="Fresh vegetables on sale"
              className="mx-auto"
            />
          </div>
          <div>
            <h1 className="text-[#323232] xl:text-[50px] lg:text-[38px] sm:text-[50px] text-[30px] font-bold">
              Trusted Foods Plants Offline & Online Store{" "}
            </h1>
            <div className="border-l-2 border-[#6aa800] my-4 py-2">
              <h2 className="pl-7 text-[#525252] xl:text-[16px] lg:text-[14px] sm:text-[16px] text-[14px]  italic font-bold">
                Providing the Freshest and Highest Quality Vegetables
              </h2>
            </div>
            <p className="text-[#525252] xl:text-[14px] lg:text-[13px]">
              Whether you're a seasoned chef looking for premium ingredients or
              a health-conscious individual seeking nutritious options, Foods
              Plants has something for everyone. With our friendly and
              knowledgeable staff ready to assist you, shopping for fresh
              vegetables has never been easier or more enjoyable.
            </p>
            <Link to="/contact" className="inline-block">
              <button className="bg-[#7cc000] py-3 px-6 text-white tracking-wide rounded-full flex items-center mt-5 border-y-2 border-white hover:bg-white hover:text-[#7cc000] hover:border-y-2 hover:border-[#7cc000] transition duration-700 ">
                Contact Us <FaArrowRightLong className="ml-2" />
              </button>
            </Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2 xl:gap-8 gap-4 mt-28 mb-10">
            <div className="flex items-center ">
                <div className="bg-[#ebebeb] xl:w-20 xl:h-20 sm:w-20 sm:h-20 lg:w-14 lg:h-14 w-14 h-14 flex items-center justify-center rounded-full mr-2">
                <FaShippingFast className="text-[#81c408] text-[30px]"/>
                </div>
               <div>
                 <h6 className="text-base text-[#191919] font-bold mb-3">Free Shipping</h6>
                <p className="text-[#6e6e6e] text-sm">Free on order over $300</p>
                </div>
            </div>
            <div className="flex items-center ">
                <div className="bg-[#ebebeb]  xl:w-20 xl:h-20 sm:w-20 sm:h-20 lg:w-14 lg:h-14 w-14 h-14 flex items-center justify-center rounded-full mr-2">
                <FaUserShield className="text-[#81c408] text-[30px]"/>
                </div>
               <div>
                 <h6 className="text-base text-[#191919] font-bold mb-3">Security Payment</h6>
                <p className="text-[#6e6e6e] text-sm">100% security payment</p>
                </div>
            </div>
            <div className="flex items-center ">
                <div className="bg-[#ebebeb]  xl:w-20 xl:h-20 sm:w-20 sm:h-20 lg:w-14 lg:h-14 w-14 h-14 flex items-center justify-center rounded-full mr-2">
                <FaExchangeAlt className="text-[#81c408] text-[30px]"/>
                </div>
               <div>
                 <h6 className="text-base text-[#191919] font-bold mb-3">30 Day Return</h6>
                <p className="text-[#6e6e6e] text-sm">30 day money guarantee</p>
                </div>
            </div>
            <div className="flex items-center ">
                <div className="bg-[#ebebeb]  xl:w-20 xl:h-20 sm:w-20 sm:h-20 lg:w-14 lg:h-14 w-14 h-14 flex items-center justify-center rounded-full mr-2">
                <FaExchangeAlt className="text-[#81c408] text-[30px]"/>
                </div>
               <div>
                 <h6 className="text-base text-[#191919] font-bold mb-3">24/7 Support</h6>
                <p className="text-[#6e6e6e] text-sm">Support every time fast</p>
                </div>
            </div>
        </div>
 
      </div>
    </div>
  );
};

export default About;
