import React from "react";
import Hero from "../components/Hero/Hero";
import OfferProduct from "../components/OfferProduct/OfferProduct";
import FruitHealth from "../components/FruitHealth/FruitHealth";
import HomeProduct from "../components/HomeProduct";

const Home = () => {
  return (
    <div>
      <Hero />
      <OfferProduct />
      <FruitHealth />
      <HomeProduct />
    </div>
  );
};

export default Home;
