import { ToastContainer } from "react-toastify";
import MainLayout from "./layouts/MainLayout";
import 'react-toastify/dist/ReactToastify.css';
import Facebook from "./components/Chat/Facebook/Facebook";
import Whatsapp from "./components/Chat/Whatsapp/Whatsapp";



function App() {
  return (
    <div >
      <Whatsapp/>
        <ToastContainer />
      <MainLayout />
      <Facebook/>
    </div>
  );
}

export default App;
