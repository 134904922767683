import React from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import ProductCard from "./ProductCard";
import { useGetProductsQuery } from '../redux/features/products/productApi';
import { Link } from 'react-router-dom';
import Loading from './Loading';



const HomeProduct = () => {
  const { data, isLoading } = useGetProductsQuery({ page: 1, limit: 8 },{refetchOnMountOrArgChange:true,pollingInterval:30000});
  if (isLoading) {
    return <div><Loading/></div>;
  }
  return (
    <div className="py-12">
      <h3 className="text-center text-3xl font-bold text-[#06BF6D]">Pre-Order Your Fresh Vegetables</h3>
      <div className="grid lg:grid-cols-4 md:grid-cols-3  grid-cols-2 gap-5 items-center justify-cente mx-auto w-11/12 lg:w-10/12 mt-5">
        {data?.data?.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
       
      </div>
      <div className='mt-12 text-center flex items-center justify-center text-[#06BF6D] font-bold cursor-pointer'>
      <Link to="/fresh-vegetable" className='text-xl inline-flex items-center space-x-1 group'>
        <span>More Product</span>
        <MdKeyboardDoubleArrowRight className='text-4xl transition-transform duration-300 group-hover:translate-x-1' />
      </Link>
    </div>
    </div>
  );
};

export default HomeProduct;
