import React from 'react';
import './Whatsapp.css'
import whatsapp from '../../../Assets/WhatsApp.svg.webp'

const Whatsapp = () => {
    return (
       <>
        <div className='whatapp-section1'>
            <div className='d-flex align-items-center'>
            <a href="https://wa.me/+8801911558258" className="whatsapp-chat" target="_blank"rel="noreferrer"> <img src={whatsapp}
             alt="whatsapp" className='Whatapp-icon'/></a>
            </div>
        </div>
  
       </>
    );
};

export default Whatsapp;