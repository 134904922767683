import React from 'react';

const Blog = () => {
    return (
        <div className=' mx-auto w-11/12 lg:w-10/12 min-[1700px]:w-[1600px]'>
            <div className="p-6 bg-white shadow-md rounded-lg my-5">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">The Vital Role of Fresh Vegetables in Our Daily Lives</h1>
            <p className="text-lg text-gray-700 mb-4">
                Welcome to Foods Plants, your trusted source for the freshest and healthiest vegetables. In today's fast-paced world, maintaining a balanced diet is more crucial than ever. Fresh vegetables are a cornerstone of a nutritious diet, offering numerous health benefits that processed or preserved options simply can't match. Let's explore why incorporating fresh vegetables into your meals is essential for a healthy lifestyle.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Nutrient-Rich Goodness</h2>
            <p className="text-lg text-gray-700 mb-4">
                Fresh vegetables are packed with essential vitamins, minerals, and antioxidants. These nutrients are vital for maintaining a healthy body and mind. For instance:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li className="text-lg text-gray-700 mb-2"><strong>Vitamins:</strong> Fresh vegetables like spinach, kale, and bell peppers are rich in vitamins A, C, and K. These vitamins are crucial for maintaining good vision, a strong immune system, and healthy skin.</li>
                <li className="text-lg text-gray-700 mb-2"><strong>Minerals:</strong> Vegetables such as broccoli, carrots, and potatoes provide essential minerals like potassium, calcium, and magnesium. These minerals help in bone health, muscle function, and maintaining a healthy heart.</li>
                <li className="text-lg text-gray-700 mb-2"><strong>Antioxidants:</strong> Fresh produce like tomatoes, berries, and leafy greens are high in antioxidants, which protect your cells from damage and reduce the risk of chronic diseases.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Better Taste and Texture</h2>
            <p className="text-lg text-gray-700 mb-4">
                There's no denying that fresh vegetables taste better. Their natural flavors are more vibrant, and their textures are more appealing. When vegetables are picked at their peak ripeness and consumed shortly after, they retain their maximum flavor and nutritional value. This enhances the overall dining experience, making meals more enjoyable and satisfying.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Weight Management and Digestive Health</h2>
            <p className="text-lg text-gray-700 mb-4">
                Incorporating fresh vegetables into your diet can aid in weight management and improve digestive health. Vegetables are low in calories and high in fiber, which helps you feel full longer and supports healthy digestion. High-fiber diets are linked to a lower risk of developing various digestive disorders and can assist in maintaining a healthy weight.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Reducing the Risk of Chronic Diseases</h2>
            <p className="text-lg text-gray-700 mb-4">
                A diet rich in fresh vegetables can significantly reduce the risk of chronic diseases such as heart disease, diabetes, and cancer. The nutrients found in fresh produce help regulate blood pressure, improve blood sugar levels, and reduce inflammation in the body. By making fresh vegetables a staple in your diet, you're investing in your long-term health and well-being.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion</h2>
            <p className="text-lg text-gray-700 mb-4">
                At Foods Plants, we are committed to providing you with the freshest and highest quality vegetables. Our dedication to health and sustainability ensures that you receive the best produce available, packed with nutrients and flavor. Make fresh vegetables a regular part of your diet and experience the numerous benefits they offer. Visit us today to explore our wide variety of fresh, locally-sourced vegetables and take the first step towards a healthier lifestyle.
            </p>
        </div>
        </div>
    );
};

export default Blog;
