import React, { useState } from 'react';
import { useGetProductsQuery } from '../redux/features/products/productApi';
import ProductCard from '../components/ProductCard';
import Pagination from '../components/ui/Pagination';
import Loading from '../components/Loading';

const Product = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const limit = 200; 

  const { data, isLoading } = useGetProductsQuery({ page, limit, searchTerm }, { refetchOnMountOrArgChange: true, pollingInterval: 30000 });
  const totalPages = data?.meta?.page || 1; 

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

    if (isLoading) {
      return <div><Loading/></div>;
    }
    return (
        <div className='mx-auto w-11/12 lg:w-10/12 py-12'>
           <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search for products..." />
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 items-center justify-center ">
       
          { 
            data?.data?.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))
          }
          
        </div>
        <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
      </div>
    );
};

export default Product;