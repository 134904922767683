import { useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from "react-icons/io";
import { addToCart,  decreaseCart, getTotals, removeFromCart } from "../redux/features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Cart = () => {
    const cart = useSelector ((state) => state.cart);
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getTotals(cart));
    }, [cart, dispatch]);
  
    const handleAddToCart = (product) => {
      dispatch(addToCart(product));
    };
    const handleDecreaseCart = (product) => {
      dispatch(decreaseCart(product));
    };
    const handleRemoveFromCart = (product) => {
      dispatch(removeFromCart(product));
    };
    return (
        <div className="bg-[#F3F4F8] py-12">
        <div className="mx-auto w-11/12 lg:w-10/12 bg-white pb-3.5">
            <div className="w-full overflow-auto">
                <table className="border-collapse w-full">
                    <thead>
                        <tr className="border-b">
                            <th className="px-5 py-[5px] text-left">Quantity</th>
                            <th className="px-5 py-[5px] text-left">Image</th>
                            <th className="px-5 py-[5px] text-left">Product Details</th>
                            <th className="px-5 py-[5px] text-left">Price</th>
                            <th className="px-5 py-[5px] text-left">Total</th>
                            <th className="px-5 py-[5px] text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart?.cartItems && cart?.cartItems?.map((cartItem) => (
                        <tr key={cartItem.id}>
                            <td className="px-5 py-[5px] border">
                                <div>
                                    <button onClick={() => handleAddToCart(cartItem)}>
                                        <IoIosArrowUp />
                                    </button>
                                    <p>{cartItem.cartQuantity}</p>
                                    <button onClick={() => handleDecreaseCart(cartItem)}>
                                        <IoIosArrowDown />
                                    </button>
                                </div>
                            </td>
                            <td className="border pl-5">
                                <img src={cartItem.img} alt={cartItem.name} className="w-[40px] h-[40px]" loading="lazy"/>
                            </td>
                            <td className="border pl-5">
                                <h3>{cartItem.name}</h3>
                            </td>
                            <td className="border pl-5">
                                <p>{cartItem.price}</p>
                            </td>
                            <td className="border pl-5">
                                <p>{cartItem.price * cartItem.cartQuantity}</p>
                            </td>
                            <td className="border pl-5">
                                <button onClick={() => handleRemoveFromCart(cartItem)}>
                                    <IoIosClose className="text-2xl" />
                                </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Link to='/checkout' className="flex justify-end my-6 mr-5"><button className="bg-[#06bf6c8e] py-2 px-6 text-[16px] font-medium">Place Order</button></Link>
        </div>
        </div>
    
    );
};

export default Cart;